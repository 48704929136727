///////////////// APP /////////////////
let $, tns;

///////////////// SLIDER /////////////////

class Slider {
    slider;

    constructor(target) { this.init(target); }

    init(target) {
        this.slider = tns({
            container   : target,
            items       : 1,
            nav         : false,
            lazyload    : true,
            mouseDrag   : true,
            mode        : 'gallery',
            preventScrollOnTouch: 'auto'
        });

        this.afterInit();

        $('.slider-prev').on('click', () => {
            this.slider.goTo('prev');
            this.setUrlParam(i);
        });

        $('.slider-next').on('click', () => {
            this.slider.goTo('next');
            this.setUrlParam(i);
        });

        let i;
        this.slider.events.on('indexChanged', () => {
            i = this.slider.getInfo().displayIndex;
            $('.slide_current').html(i);
            this.setUrlParam(i);
        });

        new Thumbnails(this.slider);
    }

    afterInit() {
        this.navigateToParam();
        let i = this.slider.getInfo();
        $('.slide_count').html(i.slideCount);
        $('.slide_current').html(i.displayIndex);
    }
    
    navigateToParam() {
        let param = this.getUrlParam('img');
        if (param) {
            param = parseInt(param) - 1;
            this.slider.goTo(param);
        }
    }

    getUrlParam(parameter = '') {
        return new URL(window.location.href).searchParams.get(parameter);
    }

    setUrlParam(parameter = '') {
        window.history.pushState(null, null, '?img=' + parameter);
    }
}
///////////////// SLIDER THUMBNAILS /////////////////
class Thumbnails {
    constructor(slider) { this.init(slider); }

    init(slider) {
        let parent = this;

        $('.slider-thumbnails-btn').on('click', () => {
            this.toggleView();
        });

        $('.slider-thumbnail').on('click', function () {
            slider.goTo($(this).data('index'));
            parent.toggleView();
        });
    }

    toggleView() {
        $('.slider-views').toggleClass('active');
    }
}

///////////////// NAV MOBILE /////////////////

class NavMobile {

    constructor() { this.init(); }

    init() {
        $('.nav-mobile-btn').on('click', () => {
            $('.nav-mobile').toggleClass('active');
        });
    }l
}


///////////////// DARK THEME /////////////////
class DarkTheme {
    bool = {
        'true': true,
        'false': false
    };

    constructor() { this.init(); }

    init() {
        $(document).keydown((e) => {
            if (e.ctrlKey && e.which == 88) {
                this.toggle();
            }
        });

        $('#darkBtn').on('click', () => {
            this.toggle();
        });

        if (this.bool[this.getValue()]) {
            $('body').toggleClass('dark');
            return;
        }
        this.setValue(false);
    }

    getValue() {
        return window.localStorage.getItem('darkThemeSetting');
    }

    setValue(newValue) {
        window.localStorage.setItem('darkThemeSetting', newValue);
    }

    toggle() {
        $('body').toggleClass('dark');
        this.setValue(!this.bool[this.getValue()]);
    }
}


///////////////// INIT PAGE /////////////////
$(document).ready(function () {
    new DarkTheme();
    new NavMobile();

    if ($('.slider').length) {
        new Slider('.slider');
    }
});
